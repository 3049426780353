<template>
  <div class="row" v-if="Object.keys(requisition).length !== 0">
    <requisition-hub-detail :requisition="requisition"></requisition-hub-detail>
    <requisition-header-detail :requisition="requisition" :spinning="spinning" :delayTime="delayTime"></requisition-header-detail>
   <requisition-header-status :current="current" :requisition="requisition"></requisition-header-status>
    <div class="col-md-12" v-if="requisition.hub_transfer_chalan_form_picture">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="float-left text-center">
              <a :href="requisition.hub_transfer_chalan_form_picture_url" target="_blank" download>
                <img :src="requisition.hub_transfer_chalan_form_picture_url" alt="" class="rounded " width="80" height="80"/>
                <div class="chalan-form-pictre-footer">Figure: Hub Received Products Chalan Form</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-hover table-bordered text-center">
            <thead>
            <tr>
              <th>SL</th>
              <th>Product Name</th>
              <th>Product Unit</th>
              <th v-if="!(user.role.includes('hub_in_charge') || user.role.includes('tejgaon_store_admin'))">W. Stock</th>
              <th>Hub Stock</th>
              <th>Status</th>
              <th>Requested</th>
              <th>Accepted</th>
              <th>Requested Comment</th>
              <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition_item, index) in requisition.requisition_items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ requisition_item.product.name }}</td>
              <td>{{ requisition_item.product.unit }}</td>
              <td v-if="!(user.role.includes('hub_in_charge') || user.role.includes('tejgaon_store_admin'))">{{ requisition_item.product.available_quantity }}</td>
              <td>{{ requisition_item.product.hub_product_stock ? requisition_item.product.hub_product_stock.stock_amount : 0 }}</td>
              <td>
                <span class="badge badge-warning" v-if="requisition_item.status === 'Pending'">Pending</span>
                <span class="badge badge-danger" v-if="requisition_item.status === 'Rejected'">Rejected</span>
                <span class="badge badge-success" v-if="requisition_item.status === 'Accepted'">Accepted</span>
                <span class="badge badge-warning" v-if="requisition_item.status === 'Partial'">Partial</span>
              </td>
              <td>{{ requisition_item.amount }}</td>
              <td>{{ requisition_item.accepted_amount }}</td>
              <td>{{ requisition_item.req_comment }}</td>
              <td>{{ requisition_item.comment }}</td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import { mapGetters } from 'vuex'
import requisitionHeaderDetail from '@/views/requisition/partial/requisition_header_detail'
import requisitionHeaderStatus from '@/views/requisition/partial/requisition_header_status'
import requisitionHubDetail from '@/views/requisition/partial/requisition_hub_detail'

export default {
  name: 'admin_view',
  components: { requisitionHeaderDetail, requisitionHeaderStatus, requisitionHubDetail },
  data() {
    return {
      requisition: {},
      loading: false,
      show: false,
      spinning: false,
      delayTime: 500,
      current: 0,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.getRequisition()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getRequisition() {
      const requisitionId = this.$route.params.requisition_id
      this.loading = true
      this.spinning = true
      apiClient.get('api/hub/requisition/view/' + requisitionId)
        .then(response => {
          this.loading = false
          this.spinning = false
          const data = response.data
          this.requisition = data.requisition
          this.current = data.max_item_requisition_status
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
table {
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}
.chalan-form-pictre-footer{
  color: #595c97;
  font-size: 16px;
  line-height: 32px;
}

</style>
